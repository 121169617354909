import SmartForm from "@/components/shared/form/SmartForm";
export default {
  components: {
    SmartForm,
  },
  data() {
    return {
      form: {},
      formFields: null,
    };
  },
  computed: {
    fields() {
      return this.formFields ?? [];
    },
    validations() {
      const validations = {};
      for (const field of this.fields) {
        if (field.multiple) {
          if (!validations[field.id]) {
            validations[field.id] = {};
          }
          for (const child of field.children) {
            validations[field.id][child.id] = this.defaultValidations[field.id][child.id];
          }
        } else {
          validations[field.id] = this.defaultValidations[field.id];
        }
      }
      return validations;
    },
  },
  methods: {
    buildForm() {
      const form = {};
      for (const field of this.formFields) {
        if (field.multiple) {
          if (!form[field.id]) {
            this.$set(form, field.id, {});
          }
          for (const child of field.children) {
            this.$set(form[field.id], child.id, null);
          }
        } else {
          this.$set(form, field.id, null);
        }
      };
      return form;
    },
    async initFormConfig() {
      this.props.form.get()
        .then(snapshot => {
          this.formFields = snapshot.get("fields");
          this.form = this.buildForm();
        })
        .catch(console.error);
    },
  },
  validations() {
    return {
      form: this.validations,
    };   
  },
  created() {
    this.initFormConfig();
  },
};