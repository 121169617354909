import {
  required,
  email,
  numeric,
  between,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

export const PETDR_CHECKOUT_FORM = {
  Name: { required },
  Email: { required, email },
  Gender: { required },
  Telefono: { required, numeric },
  PlanSelect: { required },
  petName: { required },
  dates: { required },
  especies: { required },
  raza: { required },
  petGender: { required },
};

export const PETDR_HOME_FORM = {
  petName: { required },
  breed: { required },
  date_born: { required },
  gender: { required },
  species: { required }
}

export const PETDR_CONTACT_FORM = {
  Name: { required },
  Email: { required, email },
  Comments: { required },
}

export const DTC_INSURED_FORM = {

  //INSURED FORM
  Name: { required },
  Email: { required, email },
  lastName: { required },
  contractorType: { required },

  phone: { required },
  suburb: { required },
  address: { required },
  municipality: { required },
  country: { required },
  state: { required },

}