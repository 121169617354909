<template>
  <section
    class="full-inner d-flex bg-half-170 d-table w-100"
    v-bind:style="{
      ...(props.backgroundImageURL && {
        background: `url(${props.backgroundImageURL}) no-repeat center center`,
      }),
    }"
  >
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-6">
          <div class="title-heading">
            <h1 class="display-4 font-weight-bold title-dark"
              v-html="props.title">
            </h1>
            <p class="para-desc text-muted">{{ props.subtitle }}</p>
          </div>
        </div>
        <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
          <div class="card login_page shadow rounded border-0 ml-lg-5">
            <div class="card-body">
              <h5 class="card-title text-left">Registra a tu mascota</h5>
              <form v-on:submit.prevent="handleSubmit">
                <SmartForm
                  v-bind:fields="fields"
                  v-bind:validations="$v.form"
                  v-bind:inputClasses="props.inputClasses"
                />
              </form>
              <div>
                <a :href="plans">
                  <input
                  
                  type="submit"
                  value="Cotizar Seguro"
                  class="btn btn-primary col-12 px-5 mt-3"
                  @click="imp()"
                />
                </a>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import { validationMixin } from "vuelidate";
import { PETDR_HOME_FORM as defaultValidations } from "@/utils/validations";
import PlansV2 from "../../plans/PlansV2"
export default {
  mixins: [validationMixin, formMixin],
  props: {
    props: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      defaultValidations,
      plans: this.p ,
    };
  },
  methods: {
    handleSubmit() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) return;

      console.log("Ejecutar acción!");
    },
    imp() {
      const P = PlansV2.data().plansV2_cont
      this.plans = "#"+P
      // console.log(this.plans);
    }
  },
};
</script>

<style scoped>
.full-inner {
  background-size: cover !important;
  height: 87vh;
  position: relative;
  border-radius: 0 0 70% 70%/ 0 0 10% 10%;
}
.full-inner::after {
  background-color: rgba(60, 72, 88, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.full-inner > .container {
  position: relative;
  z-index: 1;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  box-shadow: 0px 0px 153px 24px rgba(0, 0, 0, 0.12) !important;
}

</style>
