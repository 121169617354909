<template>
  <div class="row">
    <template v-for="(field, index) in fields">
      <div class="container"
        v-if="field.multiple"
        v-bind:key="index">
        <div class="row">
          <div class="col-12">
            <label>{{field.label}}</label>
          </div>
          <template v-for="child in field.children">
            <Field v-bind:key="child.id"
              v-bind:config="child"
              v-bind:validation="validations[field.id][child.id]"
              v-bind:classes="`col-md-${child.cols}`"
              v-bind:input-classes="inputClasses">
            </Field>
          </template>
        </div>
      </div>

      <Field v-bind:key="index"
        v-bind:config="field"
        v-bind:validation="validations[field.id]"
        v-bind:show-label="true"
        v-bind:input-classes="inputClasses"
        v-else>
      </Field>
    </template>
  </div>
</template>

<script>
  import Field from "./Field";
  export default {
    components: {
      Field,
    },
    props: {
      fields: Array,
      validations: Object,
      inputClasses: String,
    },
  };
</script>